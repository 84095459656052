// Importa lo necesario
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

// Componente funcional para manejar el seguimiento de página
function PageTracking() {
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    ReactGA.pageview(currentPath);
    console.log(`URL changed to: ${currentPath}`);
  }, [location]);

  return null; // No renderizamos nada en el DOM
}

export {PageTracking};
