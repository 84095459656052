import React from 'react'
import './DesktopContent.css'
import { useNavigate } from 'react-router-dom';

const DesktopContent = () => {
  const navigate = useNavigate();
    return (
      <div className='Contenedor'>
      <div className='Banner1'>
        <h1>ALBA</h1>
        <h2>¡BIENVENIDA!</h2>
        <p className='parrafo-azul'>Estás a punto de embarcarte en una aventura emocionate.</p>
        <p>Que nadie te lo cuente, <span>descúbrelo tú misma.</span></p>
      </div>
      <div className='Banner2'>
        <h1>PASO 1 <span>DE 3</span></h1>
        <p>Mira el siguiente video y sumérgete en un mundo lleno de oportunidades y emociones... <span>hagamos historia juntos!</span></p>
      </div>
      <video controls>
        <source src="https://albaholding.s3.amazonaws.com/Agendamiento/alba_seleccion_sub.mp4" type="video/mp4" />
        Tu navegador no soporta vídeos HTML5.
      </video>
      <div className='Banner3'>
        <h1>PASO 2 <span>DE 3</span> </h1>
        <p className='parrafo1'>¡Es momento de pasar al siguiente nivel!</p>
        <p className='parrafo2'>Agenda tu entrevista virtual ahora mismo para recibir asesoría personalizada y atención exclusiva que te guiará en cada paso del proceso.</p>
        <button className='boton-agendamiento' onClick={() => navigate('/agendamiento')}>AGENDA UNA ENTREVISTA</button>
      </div>
      <div className='Banner4'>
        <h1>PASO 3 <span>DE 3</span> </h1>
        <p className='parrafo1'>¡Descubre lo que nuestras modelos ALBA tienen que decir sobre su experiencia!</p>
        <p className='parrafo2'>Aquí tienes algunos videos testimoniales que te inspirarán y te darán la confianza que necesitas para confirmar por qué somos la mejor opción para ti y dar el siguiente paso</p>
        <p className='parrafo3'>¡Únete a ellas y haz realidad tus sueños!</p>
        {/* <SliderVideos /> */}
        <video width="100%" controls>
          <source src="https://albaholding.s3.amazonaws.com/Agendamiento/Testimonial1.mp4" type="video/mp4" />
          Tu navegador no soporta vídeos HTML5.
        </video>
        <video width="100%" controls>
          <source src="https://albaholding.s3.amazonaws.com/Agendamiento/Testimonial2.mp4" type="video/mp4" />
          Tu navegador no soporta vídeos HTML5.
        </video>
        <video width="100%" controls>
          <source src="https://albaholding.s3.amazonaws.com/Agendamiento/Testimonial3.mp4" type="video/mp4" />
          Tu navegador no soporta vídeos HTML5.
        </video>
        <video width="100%" controls>
          <source src="https://albaholding.s3.amazonaws.com/Agendamiento/Terstimonial4.mp4" type="video/mp4" />
          Tu navegador no soporta vídeos HTML5.
        </video>
        <video width="100%" controls>
          <source src="https://albaholding.s3.amazonaws.com/Agendamiento/Testimonial5.mp4" type="video/mp4" />
          Tu navegador no soporta vídeos HTML5.
        </video>
      </div>
      <div className='BannerBtn'>
        <button className='boton-agendamiento' onClick={() => navigate('/agendamiento')}>AGENDA UNA ENTREVISTA</button>
      </div>
    </div>
    )
  }

export {DesktopContent}