import React from 'react';
import './MobileContent.css';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';

const MobileContent = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    // Enviar evento a Google Analytics
    ReactGA.event({
      category: 'User',
      action: 'Clicked on Agenda Interview Button'
    });

    // Navegar a la página de agendamiento
    navigate('/agendamiento');
    console.log('Clicked on Agenda')
  };

  return (
    <div className='Contenedor'>
      <div className='Banner1'>
        <h1>ALBA</h1>
        <h2>¡BIENVENIDA!</h2>
        <p>Al mejor estudio de modelaje Webcam <span>en Latinoamerica</span></p>
        <p className='parrafo-azul'>Estás a punto de embarcarte en una aventura emocionante.</p>
        <p>Que nadie te lo cuente, <span>descúbrelo tú misma.</span></p>
        <button className='boton-agendamiento-firts' onClick={handleButtonClick}>AGENDA UNA ENTREVISTA</button>
      </div>
      <div className='Banner2'>
        <h1>PASO 1 <span>DE 3</span></h1>
        <p>Mira el siguiente video y sumérgete en un mundo lleno de oportunidades y emociones... <span>hagamos historia juntos!</span></p>
      </div>
      <video controls>
        <source src="https://albaholding.s3.amazonaws.com/Agendamiento/alba_seleccion_sub.mp4" type="video/mp4" />
        Tu navegador no soporta vídeos HTML5.
      </video>
      <div className='Banner3'>
        <h1>PASO 2 <span>DE 3</span> </h1>
        <p className='parrafo1'>¡Es momento de pasar al siguiente nivel!</p>
        <p className='parrafo2'>Agenda tu entrevista virtual ahora mismo para recibir asesoría personalizada y atención exclusiva que te guiará en cada paso del proceso.</p>
        <button className='boton-agendamiento' onClick={handleButtonClick}>AGENDA UNA ENTREVISTA</button>
      </div>
      <div className='Banner4'>
        <h1>PASO 3 <span>DE 3</span> </h1>
        <p className='parrafo1'>¡Descubre lo que nuestras modelos ALBA tienen que decir sobre su experiencia!</p>
        <p className='parrafo2'>Aquí tienes algunos videos testimoniales que te inspirarán y te darán la confianza que necesitas para confirmar por qué somos la mejor opción para ti y dar el siguiente paso</p>
        <p className='parrafo3'>¡Únete a ellas y haz realidad tus sueños!</p>
        {/* <SliderVideos /> */}
        <video width="100%" controls>
          <source src="https://albaholding.s3.amazonaws.com/Agendamiento/Testimonial1.mp4" type="video/mp4" />
          Tu navegador no soporta vídeos HTML5.
        </video>
        <video width="100%" controls>
          <source src="https://albaholding.s3.amazonaws.com/Agendamiento/Testimonial2.mp4" type="video/mp4" />
          Tu navegador no soporta vídeos HTML5.
        </video>
        <video width="100%" controls>
          <source src="https://albaholding.s3.amazonaws.com/Agendamiento/Testimonial3.mp4" type="video/mp4" />
          Tu navegador no soporta vídeos HTML5.
        </video>
        <video width="100%" controls>
          <source src="https://albaholding.s3.amazonaws.com/Agendamiento/Terstimonial4.mp4" type="video/mp4" />
          Tu navegador no soporta vídeos HTML5.
        </video>
        <video width="100%" controls>
          <source src="https://albaholding.s3.amazonaws.com/Agendamiento/Testimonial5.mp4" type="video/mp4" />
          Tu navegador no soporta vídeos HTML5.
        </video>
      </div>
      <div className='BannerBtn'>
        <button className='boton-agendamiento' onClick={handleButtonClick}>AGENDA UNA ENTREVISTA</button>
      </div>
    </div>
  );
}

export { MobileContent };