import './App.css';
import React, { useState, useEffect } from 'react';
import {Calendario} from './components/Calendario/index';
import { MobileContent } from './components/MobileContent';
import { DesktopContent } from './components/DesktopContent';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import { PageTracking } from './components/PageTracking/index';

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 425);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    ReactGA.initialize('G-6NDPNKGHPB');
  }, []);

  return (
    <Router>
      <div>
        <PageTracking /> {/* Aquí integras el componente PageTracking */}
        <Routes>
          <Route path="/" element={<MobileContent />} />
          <Route path="/agendamiento" element={<Calendario />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
