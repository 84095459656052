import React from 'react'

const Calendario = () => {
    return (
        <iframe
          src="https://outlook.office365.com/owa/calendar/AgendaAlbaStudio@albagroup.com.co/bookings/"
          style={{ width: '100%', height: '100vh', border: 'none', overflow: 'hidden' }}
          title="Booking Calendar"
        ></iframe>
      );
}

export { Calendario }